<template>
  <app-chip
    class="bg-primary-50 ring-1 ring-primary-100"
    :deletable="deletable"
    @delete="$emit('delete')"
  >
    <app-badge
      :class="statusBadge?.class"
      :hide="!status"
      :icon="statusBadge?.icon"
    >
      <app-user-avatar
        :avatar-url="member.user.avatar_url"
        class="!h-6 !w-6"
        :name="member.user.full_name"
        :size="48"
      />
    </app-badge>

    <span class="text-sm font-normal">{{ label }}</span>
  </app-chip>
</template>

<script lang="ts" setup>
import type { OrganizationMemberView } from "~~/model/organization.model";

const properties = defineProps<{
  member: OrganizationMemberView;
  deletable?: boolean;
  status?: DatabaseEnum<"course_session_registration_request_status">;
}>();

defineEmits(["delete"]);

const { me } = useCurrentUser();

const { t } = useI18n();
const label = computed(() => {
  const name = properties.member.user.full_name;

  if (me.value?.id === properties.member.user_id)
    return `${name} (${(t("app.form.labels.you") as string).toLowerCase()})`;

  return name;
});

const statusBadge = computed<{ icon?: string; class?: string } | undefined>(
  () => {
    if (!properties.status) return;

    if (properties.status === "ACCEPTED")
      return {
        icon: "ph:check-bold",
        class: "bg-success text-white",
      };

    if (properties.status === "PENDING")
      return {
        icon: "ph:hourglass-high-fill",
        class: "bg-deco2",
      };
  },
);
</script>
